import React from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import '../../styles/nimmi.scss';

const Nimmi = () => {
  return (
    <div className='uk-container uk-container-medium'>
      <Helmet
        htmlAttributes={{
            class: 'bd-nimmi-sample'
        }}>
          <title>Brolga Digital || Nimmi's Hair Studio</title>
      </Helmet>

      {/* NAVIGATION */}
        <div uk-navbar='' className='uk-navbar-container uk-navbar-transparent'>
            <div className='uk-navbar-left'>
                <Link className="uk-navbar-item uk-logo" to="#">nimmi hair & beauty</Link>
            </div>
            <div className='uk-navbar-right'>
                <ul className='uk-navbar-nav'>
                    <li><Link to='#'>services</Link></li>
                    <li><Link to='#'>gallery</Link></li>
                    <li><Link to='#'>about</Link></li>
                    <li><Link to='#'>contact</Link></li>
                </ul>
            </div>
        </div>

        {/* HERO SECTION */}
        <div className="uk-section uk-section-primary uk-section-xsmall uk-padding-remove-vertical">
            <div className='uk-flex uk-child-width-1-2'>
                <div className='uk-padding uk-flex uk-flex-column uk-flex-center' style={{alignItems: 'flex-start'}}>
                    <p className='uk-margin-remove-bottom'>Welcome to</p>
                    <h1 className='uk-margin-remove main-logo'>nimmi hair & beauty</h1>
                    <p>Professional hair and beauty studio located in Altona Meadows </p>
                    <button className="uk-button uk-button-primary">Call to Book: 9315 6868</button>
                </div>
                <StaticImage src="../../images/nimmi/hero.jpeg" alt="" />
            </div>
        </div>

        {/* SERVICES */}
        <div className="uk-section uk-section-default uk-section-xsmall uk-dark">
            <div className='uk-grid-small uk-grid-match uk-child-width-1-3@s' uk-grid=''>
                <div>
                    <div className='uk-card uk-card-primary uk-flex uk-flex-column uk-flex-wrap-between'>
                        <div className='uk-card-body uk-text-center'>
                            <h2 className='uk-h1'>hair services</h2>
                            <ul className='uk-list'>
                                <li>Style Cuts and Finish</li>
                                <li>Styled Up-Dos</li>
                                <li>Colours</li>
                                <li>Foils</li>
                                <li>Perming / Waves</li>
                                <li>Chemical Straightening</li>
                                <li>Blowaves / Sets</li>
                                <li>Bridal Hair Ups</li>
                                <li>Men's Cuts & Styles</li>
                            </ul>
                        </div>
                        <div className='uk-card-footer uk-text-center'>
                            <Link to='#' className='uk-button uk-button-primary'>Learn More</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='uk-card uk-card-primary uk-flex uk-flex-column uk-flex-wrap-between'>
                        <div className='uk-card-body uk-text-center'>
                        <h2 className='uk-h1'>beauty services</h2>
                        <ul className='uk-list'>
                            <li>Men's & Ladies Waxing</li>
                            <li>Eye Brow Threading</li>
                            <li>Eye Lash Extensions</li>
                            <li>Manicures</li>
                            <li>Spa Pedicures</li>
                            <li>Spray Tanning</li>
                            <li>Facials</li>
                            <li>Massage</li>
                        </ul>
                        </div>
                        <div className='uk-card-footer uk-text-center'>
                            <Link to='#' className='uk-button uk-button-primary'>Learn More</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='uk-card uk-card-primary uk-flex uk-flex-column uk-flex-wrap-between'>
                        <div className='uk-card-body uk-text-center'>
                        <h2 className='uk-h1'>packages & discounts</h2>
                        <ul className='uk-list'>
                            <li>Bridal Parties</li>
                            <li>Formals & Graduations</li>
                            <li>Spa Pampering</li>
                        </ul>
                        </div>
                        <div className='uk-card-footer uk-text-center'>
                            <Link to='#' className='uk-button uk-button-primary'>Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* GALLERY */}
        <div className="uk-section uk-section-default uk-padding-remove-top uk-section-xsmall">
            <div className="uk-slider-container">
                <ul className="uk-slider-items uk-child-width-1-4 bd-image-fade">
                    <li>
                        <StaticImage src="../../images/nimmi/img1.jpeg" alt="" />
                    </li>
                    <li>
                        <StaticImage src="../../images/nimmi/img2.jpeg" alt="" />
                    </li>
                    <li>
                        <StaticImage src="../../images/nimmi/img3.jpeg" alt="" />
                    </li>
                    <li>
                        <StaticImage src="../../images/nimmi/img4.jpeg" alt="" />
                    </li>
                </ul>
            </div>
        </div>

        {/* CONTACT */}
        <div className="uk-section uk-section-primary uk-section-xsmall">
            <div className='uk-padding uk-padding-remove-vertical'>
                <h2 className='uk-h1'>contact us</h2>
                <p>We take all our bookings by phone. For other enquiries, please use the form below</p>
                <button className="uk-button uk-button-primary">Call to Book: 9315 6868</button>
                <form className='uk-grid-small uk-padding uk-padding-remove-horizontal' uk-grid=''>
                    <div className='uk-width-1-2@s'>
                        <label className='uk-display-block uk-margin'>Full Name
                            <input className='uk-input' type='text' placeholder='John Doe'></input>
                        </label>
                        <label className='uk-display-block uk-margin'>Email
                            <input className='uk-input' type='email' placeholder='user@location.com.au'></input>
                        </label>
                    </div>
                    <div className='uk-width-1-2@s'>
                        <label className='uk-display-block uk-margin'>Message
                            <textarea className='uk-textarea uk-height-medium'></textarea>
                        </label>
                        <button type='submit' className='uk-button uk-button-primary'>Send Message</button>
                    </div>
                </form>
            </div>
        </div>

        {/* FOOTER */}
        <hr></hr>
        <div className="uk-section uk-section-default">
            <div className='uk-container uk-flex uk-flex-bottom uk-flex-between'>
                <div>
                    <ul className='uk-nav footer-nav'>
                        <li><Link className="uk-navbar-item uk-logo" to="#">nimmi hair & beauty</Link></li>
                        <li><button className='uk-button uk-button-primary uk-margin-bottom'>Call to Book: 9315 6868</button></li>
                        <li><p>3/4 South Avenue<br/>Altona Meadows 3028</p></li>
                        <li><p>9am - 5pm Tuesday to Saturday</p></li>
                    </ul>
                </div>
                <div>
                    <div>
                        <Link to='#' className='uk-icon-button uk-margin-right' uk-icon="icon: facebook"></Link>
                        <Link to='#' className='uk-icon-button' uk-icon="icon: instagram"></Link>
                    </div>
                    <ul className='uk-nav uk-nav-defult uk-text-right uk-margin-small'>
                        <li><Link to='#' className='uk-flex-row-reverse'>home</Link></li>
                        <li><Link to='#' className='uk-flex-row-reverse'>services</Link></li>
                        <li><Link to='#' className='uk-flex-row-reverse'>gallery</Link></li>
                        <li><Link to='#' className='uk-flex-row-reverse'>about</Link></li>
                        <li><Link to='#' className='uk-flex-row-reverse'>contact</Link></li>
                    </ul>
                </div>
            </div>
        </div>

        {/* COPYRIGHT */}
        <hr />
        <div className='bd-copyright uk-text-center uk-padding-small'>
            <p className='uk-margin-remove-bottom'>&copy; <Link to='https://brolgadigital.com.au'>Brolga Digital</Link> {new Date().getFullYear()}</p>
        </div>
    </div>
  )
}

export default Nimmi